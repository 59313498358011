<script lang="ts" context="module">
    import cn from 'classnames';
    import success from '$lib/images/kosik/success-basket-added.svg';

    let onTop: any; //keeping track of which open modal is on top
    const modals: any = ['']; //all modals get registered here for easy future access
    let parameterName = '';
    let scooterSlug = '';

    // 	returns an object for the modal specified by `id`, which contains the API functions (`open` and `close` )
    export function getModal(_name: string, _scooterName: string) {
        parameterName = _name;
        scooterSlug = _scooterName;
        return modals[''];
    }
</script>

<script lang="ts">
    import { onDestroy } from 'svelte';
    import CartFreeShipping from '../(components)/(cart)/CartFreeShipping.svelte';
    import Close from '$lib/images/svg/close.svelte';
    import Button from '../(components)/Button.svelte';
    import Input from "../(components)/(cart)/Input.svelte";

    let topDiv: any;
    let visible = false;
    let prevOnTop: any;
    let closeCallback: any;
    let parName: string;
    let scoSlug: string;
    let parEmail: string;
    let parValue: string = '';
    let isSended: boolean = false;

    export let id = '';

    function keyPress(ev: any) {
        if (ev.key == 'Escape' && onTop == topDiv) console.log('close');
        //close()
    }

    /**  API **/
    function open(callback: any) {
        closeCallback = callback;
        console.log(visible)
        if (visible) return;
        prevOnTop = onTop;
        onTop = topDiv;
        scoSlug = scooterSlug
        parName = parameterName
            .replace("Vin rám (pozice)", "Kde najdu VIN?")
            .replace("Vin štítek (pozice)", "Kde najdu výrobní štítek?");
        document.body.style.overflow = 'hidden';
        visible = true;
    }

    function close(retVal: any) {
        if (!visible) return;
        if (isSended) isSended = false;
        onTop = prevOnTop;
        if (onTop == null) document.body.style.overflow = '';
        visible = false;
        if (closeCallback) closeCallback(retVal);
    }
    
    function sendData(data: string) {
        fetch("https://general.scootland.cz/api/v1/scooters/" + scoSlug + "/technical-parameters?email="+parEmail+"&typ=" + parName + "&value=" + parValue +
            "&key=ly8XPHrquYLC2rjotoja4cYlZ", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: parEmail }),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        
        isSended = true;
        parEmail = '';
        parValue = '';
        parName = '';
        
        
        
    }

    //expose the API
    modals[id] = { open, close };

    onDestroy(() => {
        delete modals[id];
    });
</script>

<div
        class={cn(
		'fixed left-1/2 top-1/2 z-[1000] flex max-h-[calc(100vh-1.25rem)] w-[calc(100vw-1.25rem)] max-w-[60.625rem] -translate-x-1/2 -translate-y-1/2 flex-col bg-white shadow-sm transition-transform md:shadow-custom',
		visible ? 'visible' : 'invisible'
	)}
>
    <button
            on:click={() => close(null)}
            on:keydown
            on:keypress
            on:keyup
            class="text-gray-lighter4 absolute right-0 top-0 cursor-pointer p-2 transition-transform hover:scale-125 md:p-8"
    >
        <Close class="h-4 w-4" />
    </button>

    <h1
            class="border-gray-lightest flex flex-row items-center gap-x-4 px-12 py-7 align-middle text-xl font-bold md:px-16 md:py-10 md:text-2xl mdmax:border-b-2"
    >
        {#if isSended}
            <img src={success} width="45" alt="success" />Technický parametr odeslán
        {:else}
            Doplnění technického parametru
        {/if}
    </h1>

    <div class="custom-vertical-scrollbar overflow-auto">
        <div class="mx-2.5 md:mx-16 md:mb-4">
            {#if !isSended}
            <Input id="parametr" label={parName} placeholder="Zadejte hodnotu" 
                   bind:value={parValue}  />
                <Input id="email" label="Váš email (nepovinné)" placeholder="Zadejte váš email"
                       bind:value={parEmail}  />
            {/if}
            <div class="mt-4 flex flex-col gap-y-2 md:flex-row md:justify-end md:gap-x-2">
                <Button onClick={() => close(null)} isDark={true} class="py-3">Zpět</Button>
                {#if !isSended}
                    <Button class="py-3" isDisabled={parValue?.length < 2} onClick={() => sendData(parValue)}>
                        Odeslat
                    </Button>
                {/if}
            </div>
        </div>


        <div class="flex flex-col md:px-16 md:pb-10">
            
        </div>
    </div>
</div>

<div
        class={cn(
		'fixed bottom-0 left-0 right-0 top-0 z-[999] flex items-center justify-center bg-black bg-opacity-60',
		visible ? 'visible' : 'invisible'
	)}
        on:click={() => close()}
        on:keydown
        on:keypress
        on:keyup
/>
